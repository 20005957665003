import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import cookie from "react-cookies";
import api from "../services/login";

/**
 * @param {number} [intervalValidaRefreshToken=60000] Tempo em milissegundos para atualizar o token - default: 2000 (2s)
 * @param {number} [interValRefreshToken=600000] Tempo em milissegundos para validar a atualização do token - default: 1800000 (30min)
 */
function useRefreshToken(intervalValidaRefreshToken = 6000, interValRefreshToken = 1800000) {
    let history = useHistory();
    const LogOut = useCallback(() => {
        cookie.remove("authed", { path: "/" });
        cookie.remove("locale", { path: "/" });
        cookie.remove("cliente", { path: "/" });
        cookie.remove("moeda", { path: "/" });
        cookie.remove("role", { path: "/" });
        localStorage.clear();
        sessionStorage.clear();
    
        history.push("/");
        history.go(0);
    }, [history]);

    const fetchData = useCallback(async () => {
        try {
            const { data } = await api.post("api/login/refresh-token");
            const result = data.result;
            if(result) {
                const { authed: token } = result;
                if(token) {
                    localStorage.setItem("ls-dtaRefreshToken", new Date());
                    localStorage.setItem("Token", token);
                } else {
                    throw new Error("Erro ao atualizar token automáticamente");
                }
            }
            else {
                LogOut();
            }
        } catch (e) {
            console.log(e);
        }
    }, [LogOut]);

    useEffect(() => {
        const intervalId = setInterval(async () => {
            const dtaRefreshToken = localStorage.getItem("ls-dtaRefreshToken");
            
            // apenas dispara request p/ endpoint 'refresh-token' no intervalo definido em intervalRefreshToken
            if (!dtaRefreshToken || Date.now() - new Date(dtaRefreshToken).getTime()  >= interValRefreshToken) {
                await fetchData();
            }
        }, intervalValidaRefreshToken);

        return () => {
            clearInterval(intervalId);
        };
    }, [fetchData, interValRefreshToken, intervalValidaRefreshToken]);
}

export default useRefreshToken;