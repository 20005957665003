import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_DNS_CORE}/`,
});

api.interceptors.request.use(async (config) => {
  const TOKEN = await window.localStorage.getItem("Token");

  try {
    config.headers.Authorization = `Bearer ${TOKEN}`;
    return config;
  } catch (err) {
    console.log("err", err);
  }
});

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error && error.response && error.response.status === 401) {
      toast.warn("Faça login novamente");

      localStorage.clear();
      sessionStorage.clear();
      window.location = "/";
    } else {
      return Promise.reject(error);
    }
  }
);

export default api;
