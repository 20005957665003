import React, { Fragment } from "react";
import { useSelector } from "react-redux";

// components
import SideBar from "../../components/SideBar";
import SideNav from "../../components/SideNav";
import Footer from "../../components/Footer";
import layoutHelpers from "../../shared/layouts/helpers";

export default function Panel({ contentOnly, ...props}) {
  // buscando do redux se a posição do menu é vertical ou horizontal
  const position = useSelector((state) => state.viewMode.position);

  const closeSidenav = () => {
    layoutHelpers.setCollapsed(true);
  };

  return (
    <Fragment>
      { contentOnly ? (<>{props.children}</>) : 
      (
        position === "vertical" ? (
          <div className="layout-wrapper layout-1">
            <div className="layout-inner">
              <SideBar sidenavToggle={true} />
              <div className="layout-container">
                <SideNav position={position} />
                <div className="layout-content">
                  <div className="container-fluid flex-grow-1 container-p-y">
                    {props.children}
                  </div>
                  <Footer />
                </div>
              </div>
            </div>
            <div className="layout-overlay" onClick={() => closeSidenav()}></div>
          </div>
        ) : (
          <div className="layout-wrapper layout-1 layout-without-sidenav">
            <div className="layout-inner">
              <div className="layout-navbar ">
                <SideBar sidenavToggle={false} />
              </div>
              <div className="layout-container h-100 mb-5">
                <div className="layout-content">
                  <div className="sidenav-horizontal">
                    <SideNav position={position} />
                    <div className="container-fluid flex-grow-1 container-p-y ">
                      {props.children}
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        )
      )}
    </Fragment>
  );
}
