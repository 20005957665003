import { addLocale, useLocale } from "ttag";

let locale = localStorage.getItem("Locale");

if (!locale) {
    locale = "PT-BR";
    localStorage.setItem("Locale", locale);
}

const translationsObj = require(`./i18n/${locale.toLocaleLowerCase()}.po.json`);
addLocale(locale, translationsObj);
useLocale(locale);