import axios from "axios";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_DNS}/`,
});

api.interceptors.request.use(async (config) => {
  const TOKEN = await window.localStorage.getItem("Token");

  try {
    config.headers.Authorization = `Bearer ${TOKEN}`;

    return config;
  } catch (err) {
    console.log("err", err);
  }
});

export default api;
