import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedCliente, disableTodos } from "../../store/actions/cliente/clienteAction";
import { usePageVisibility } from "../../utils/visibility";
import { useRequesitionGet } from "../../hooks/useRequesition";
import { useLocation } from "react-router-dom";

export default function ComboCliente() {
  const dispatch = useDispatch();
  const location = useLocation();
  
  const [showComboCliente, setShowComboCliente] = useState(false);
  const { data: clientes } = useRequesitionGet(`v1/cliente`);
  const [optionsClientes, setOptionsClientes] = useState([]);

  const selectedCliente = useSelector((state) => state.cliente.selectedCliente);
  const isPendingLabel = useSelector((state) => state.cliente.isPendingLabel);
  const pendingCodCliente = useSelector((state) => state.cliente.pendingCodCliente);
  const enableClienteTodos = useSelector((state) => state.cliente.enableTodos);
  const listaPathCliTodos = useSelector((state) => state.cliente.listaPathCliTodos);

  // esquema para conseguir passar de uma tab para outra, o cliente selecionado
  // somente para casos em que o usuário clica para abrir uma nova aba
  const isVisible = usePageVisibility();

  if (isVisible && selectedCliente && selectedCliente !== "null" && selectedCliente?.value !== '' && selectedCliente?.label !== '...') {
    localStorage.setItem("selectedCliente", JSON.stringify(selectedCliente));
  }

  useEffect(() => {
    // mudou de página precisa resetar o "TODOS"
    if (location) {
      const objSelectedCliente = localStorage.getItem("selectedCliente");

      if (objSelectedCliente)
        dispatch(setSelectedCliente(JSON.parse(objSelectedCliente)));

      if (listaPathCliTodos && !listaPathCliTodos.find(e => e === location.pathname))
        dispatch(disableTodos());

    }
  }, [dispatch, listaPathCliTodos, location]);

  useEffect(() => {
    if (clientes) {
      const lista =
        [ ...clientes.map((item) => ({
          value: item?.codCliente,
          label: item?.nomCliente,
          })),
          ...((enableClienteTodos) ? [{ label: 'TODOS', value: '' }] : [])
        ];
  
      if (isPendingLabel) {
        const item = lista.find(i => i?.value === pendingCodCliente);

        if (item) {
          dispatch(setSelectedCliente(item));
        }
      }

      setOptionsClientes(lista);
    }
  }, [clientes, enableClienteTodos, isPendingLabel]);

  useEffect(() => {
    setShowComboCliente(!selectedCliente);

  }, [selectedCliente])

  const handleClienteChange = (e) => {
    setShowComboCliente(!showComboCliente);

    dispatch(setSelectedCliente(e));
    localStorage.removeItem("ls-listaPathCliTodos");
  };

  return (<>
    <i
      className="d-block ion ion-ios-business navbar-icon align-middle btn btn-sm icon-btn btn-outline-secondary borderless"
      onClick={() => setShowComboCliente(!showComboCliente)}
    ></i>
    {showComboCliente ? (
        <div style={{"width": "200px"}}>
        <Select
          className={`custom-select p-0 border-1 ml-1 ${(!selectedCliente) ? 'is-invalid' : ''}`}
          classNamePrefix="select"
          options={optionsClientes}
          onChange={handleClienteChange}
          value={selectedCliente}
          placeholder={`Selecione o cliente...`}
        />
        </div>
    ) : (
      <span
        className="text-black-sidebar ml-1 cursor-pointer"
        onClick={() => setShowComboCliente(true)}
      >
        {`${selectedCliente?.label || `Nenhum cliente selecionado`}`}
      </span>
    )}
  </>)
}
