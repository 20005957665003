const INITIAL_STATE = {
  position: "horizontal",
};

const fileProgressReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_VIEW_MODE":
      return {
        ...state,
        position: action.payload,
      };
    default:
      return state;
  }
};

export default fileProgressReducer;
