import styled, { keyframes, css } from "styled-components";

export const messageAnimation = keyframes`
 0%{
   transform: scale(1)
 }
70%{
    transform: scale(1.1)
  }
100%{
    transform: scale(1)
  }
`;

export const BadgeIcon = styled.i.attrs((props) => props.msg)`
  position: relative;
  left: 5px;
  ${(props) =>
    props.msg > 0 &&
    css`
      animation: ${messageAnimation} 1s infinite;
    `}
`;

export const badgeAnimation = keyframes`
 0%{
   transform: scale(.9)
 }
70%{
    transform: scale(1)
  }
100%{
    transform: scale(.9)
  }
`;

export const BadgeSpan = styled.span.attrs((props) => props.msg)`
  position: absolute;
  top: auto;
  display: inline-block;
  margin: 0;
  -webkit-transform: translate(-50%, 25%);
  transform: translate(-50%, 25%);
  padding: 0;
  width: 0.6em;
  height: 0.6em;
  right: 1px;
  top: 11px;
  border-radius: 50%;
  vertical-align: middle;
  background-color: #e57878;
  color: #fff;
  ${(props) =>
    props.msg > 0 &&
    css`
      animation: ${badgeAnimation} 1s infinite;
    `}
`;
