import React, { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";

//styles Components
import { Div } from "./style";
import { t } from "ttag";

export default function Footer() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Fragment>
      <Div className="layout-footer footer bg-footer-theme">
        <div className="container-fluid d-flex flex-wrap justify-content-between text-center container-p-x pb-3">
          <div className="pt-3">
            <span className="footer-text font-weight-bolder">BTB Soluções</span>{" "}
            ©
          </div>
          <div className="pt-3">
            <button onClick={handleShow} className="btn btn-link">
              <span className="footer-text">{t`Política de Privacidade`}</span>
            </button>
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            scrollable={true}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>{t`Política de Privacidade - BTB Soluções`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <section>
                <p>{t`Esta Política de Privacidade define os requisitos de segurança da informação e o compromisso com a privacidade e o uso legítimo das informações dos USUÁRIOS e constitui um acordo entre USUÁRIO e a GBM2 Tecnologia e Serviços Adm LTDA, com nome fantasia BTB Soluções, e, para os efeitos desta política, será definida como “BTB”.`}</p>
                <p>{t`O aceite dos termos definidos abaixo representa e confirma nossa concordância mútua com relação à política de privacidade do presente site nos seguintes termos:`}</p>
              </section>
              <section>
                <h4>{t`Introdução`}</h4>
                <p>{t`Nesta política estão definidas as regras aplicáveis à utilização deste site de acordo com a Lei 13.709, de 14 de agosto de 2018 (Lei Geral de Proteção de Dados Pessoais - LGPD).`}</p>
                <p>{t`Quando um USUÁRIO acessa e interage com nosso website, poderá ser requisitado o fornecimento de alguns dados pessoais, como nome, telefone e e-mail, portanto, esta política se aplicará caso dados pessoais sejam fornecidos pelo USUÁRIO.`}</p>
                <p>{t`Ao acessar nosso site, você expressa seu consentimento a BTB quanto o direito de uso sobre os dados coletados e compartilhados nos moldes das finalidades e condições estabelecidas nesta política.`}</p>
              </section>
              <section>
                <h4>{t`Definições`}</h4>
                <p>{t`Para entendimento dos termos utilizados nesta Política de Privacidade, consideram-se:`}</p>
                <p><b>{t`Site:`}</b> {t`Meio eletrônico pelo qual o USUÁRIO acessa informações e conteúdos disponibilizados;`}</p>
                <p><b>{t`Usuário:`}</b> {t`Pessoa natural que acesse informações e o conteúdo deste site;`}</p>
                <p><b>{t`Tratamento:`}</b> {t`Operação realizada com dados pessoais do USUÁRIO, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.`}</p>
                <p><b>{t`Terceiro:`}</b> {t`Pessoa ou instituição que não integra diretamente a estrutura da BTB.`}</p>
                <p><b>{t`Cookies:`}</b> {t`São pequenos arquivos que páginas web acessadas armazenam nos navegadores;`}</p>
                <p><b>{t`Códigos maliciosos:`}</b> {t`Se refere a um programa de computador, ou parte de um programa, cuja intenção é causar danos, obter informações não autorizadas ou prejudicar o funcionamento de sistemas, redes e/ou programas de computadores.`}</p>
              </section>
              <section>
                <h4>{t`Direitos do Usuário`}</h4>
                <p>{t`Em conformidade com os requisitos previstos na Lei nº 13.709, de 14 de agosto de 2018 (Lei Geral de Proteção de Dados Pessoais - LGPD), todo USUÁRIO possui os seguintes direitos, conferidos pela Lei de Proteção de Dados Pessoais: Direito de confirmação e acesso; Direito de retificação; Direito de não ser submetido a decisões automatizadas.`}</p>
              </section>
              <section>
                <h4>{t`Conformidade da Coleta`}</h4>
                <p>{t`O processo de coleta de dados dos USUÁRIOS será realizado em conformidade a legislação, com o disposto nesta Política de Privacidade e ocorrerá mediante interação com nosso site e acesso ao seu conteúdo.`}</p>
                <p>{t`Nosso site irá realizar o tratamento dos dados pessoais fornecidos com finalidade legítima, específica, explícita e claramente informada aos USUÁRIOS, ratificando o compromisso de não realizar tratamento posterior incompatível com as finalidades declaradas.`}</p>
                <p>{t`É válido ressaltar que, durante a interação com este site, não haverá coletar dados sensíveis conforme descrito no artigo 5º, II, da Lei Geral de Proteção de Dados Pessoais, como, por exemplo: Dados que revelem a origem racial ou étnica, opiniões políticas, convicções religiosas ou filosóficas, ou dados biométricos. Portanto, ratifica-se que tais informações não serão tratadas em nenhuma hipótese pelo nosso site.`}</p>
              </section>
              <section>
                <h4>{t`Dados Coletados`}</h4>
                <p>{t`Em nosso site, ao preencher formulários para entrar em contato conosco, as informações pessoais que são fornecidas pelos USUÁRIOS, tais como, nome, telefone para contato e e-mail que são coletados e armazenados pela BTB. Durante a navegação no site alguns dados também podem ser coletados por meio de cookies.`} </p>
                <p>{t`Internamente é mantido um rígido controle quanto ao acesso aos dados dos USUÁRIOS, ou seja, apenas pessoas devidamente autorizadas podem ter acesso aos dados pessoais e apenas para as finalidades previstas nesta política.`} </p>
                <p>{t`Além disso, a BTB possui mecanismos para promover a segurança das informações para que não haja qualquer acesso não autorizado ou uso indevido dos dados dos USUÁRIOS.`} </p>
              </section>
              <section>
                <h4>{t`Solicitação do Usuário`}</h4>
                <p>{t`Caso o USUÁRIO tenha interesse em exercer seus direitos em relação aos seus dados pessoais tais como, solicitação de alteração; eliminação, correção, dentre outros, deve entrar em contato através de nosso e-mail suporte@btbsolucoes.com.`}</p>
              </section>
              <section>
                <h4>{t`Finalidade da Coleta`}</h4>
                <p>{t`As informações que o USUÁRIO compartilha conosco têm como finalidade atender as demandas enviadas via formulário, tais como, dar suportes a clientes, receber solicitações para trabalhar conosco ou atender fornecedores. As informações oriundas da navegação têm por finalidade o aprimoramento das funcionalidades do site.`} </p>
                <p>{t`Para as finalidades acima expostas, foi respeitado o princípio da necessidade onde é solicitado o mínimo de dados possíveis, tais como, nome, telefone e e-mail.`}  </p>
                <p>{t`A coleta de dados foi limitada ao mínimo necessário para a realização de suas finalidades, requerendo somente dados relevantes evitando, assim, excessos para realização do tratamento de dados e suas respectivas finalidades.`} </p>
              </section>
              <section>
                <h4>{t`Compartilhamento dos Dados`}</h4>
                <p>{t`Ressaltamos que as informações coletadas não serão vendidas, não serão divulgadas publicamente e não serão repassadas indevidamente para terceiros. O compartilhamento ocorrerá somente com terceiros que estejam em conformidade com a legislação vigente e para cumprimento das finalidades mencionada nesta política.`}</p>
              </section>
              <section>
                <h4>{t`Site de Terceiros`}</h4>
                <p>{t`Ao acessar nosso site é possível que o USUÁRIO tenha acesso a links ou conteúdo produzido por terceiros. Nossa política de privacidade não se aplica a site de terceiros que podem estar vinculados ao nosso site. Portanto, recomendamos a leitura da política deles.`}</p>
              </section>
              <section>
                <h4>{t`Cookies`}</h4>
                <p>{t`Utilizamos cookies ou tecnologias similares para fazer o site ter o melhor desempenho possível e sempre aperfeiçoar sua navegação. Para obter mais informações sobre o tipo de cookies que utilizamos, acesse nossa Política de Cookies.`}</p>
                <p>{t`O controle sobre o compartilhamento de informações por cookie se dá por meio do navegador de cada USUÁRIO. Portanto, para evitar total ou parcialmente o compartilhamento por essa tecnologia, será necessário modificar as configurações no navegador. Para modificar essas considerações, verifique o navegador utilizado e siga as instruções.`}</p>
              </section>
              <section>
                <h4>{t`Proteção dos Dados`}</h4>
                <p>{t`A BTB tem como compromisso utilizar medidas técnicas e administrativas para proteger os dados pessoais de acessos não autorizados, tratamentos inadequados e de situações que possam causar a destruição, perda, alteração, comunicação ou difusão de tais dados.`}</p>
                <p>{t`Em casos de incidente de segurança que possa gerar risco ou dano relevante aos USUÁRIOS por conta da exposição dos dados pessoais coletados, nosso compromisso é comunicar ao USUÁRIO, em prazo adequado, conforme definido pela Autoridade Nacional de Proteção de Dados.`}</p>
                <p>{t`Considera-se um incidente de dados pessoais uma violação a segurança da informação que, sendo causada interna ou externamente, de modo acidental ou ilícito, possa causar a destruição, a perda, a alteração, a divulgação ou o acesso não autorizado a dados pessoais tratados pelo controlador e operador dos dados.`}</p>
                <p>{t`Para mitigar potenciais danos e evitar incidentes de segurança, este site poderá ficar inacessível, de forma parcial ou total, em caso de suspeita ou identificação de possíveis violações da segurança.`} </p>
                <p>{t`Caso o USUÁRIO identifique ou entenda que sua conta ou seus dados não estão protegidos, deverá notificar imediatamente através do e-mail suporte@btbsolucoes.com.`}</p>
                <p>{t`Nesta política apresentamos nosso compromisso em adotar todas as medidas, salvaguardas e mecanismos de mitigação de risco possíveis, aptas a proteger os dados pessoais. Portanto, o tratamento dos dados pessoais dos USUÁRIOS será realizado respeitando a confidencialidade e em conformidade com a legislação vigente.`} </p>
              </section>
              <section>
                <h4>{t`Alteração desta Política de Privacidade`}</h4>
                <p>{t`Nos reservamos o direito de alterar e/ou atualizar, a qualquer momento e sempre que necessário, as informações constantes na presente Política de Privacidade.`}</p>
                <p>{t`Caso a Autoridade Nacional de Proteção de Dados determine novas recomendações para promover a proteção dos dados pessoais, esta política também poderá sofrer alterações.`} </p>
                <p>{t`As alteração ou atualização desta política só devem ser consideradas vigentes e plenamente aplicáveis após devida publicação no aplicativo, razão pela qual eventuais serviços prestados anteriormente à atualização serão regulados pelos termos vigentes à época.`}</p>
                <p>{t`Recomendamos que cada USUÁRIO, ao acessar o site, consulte nossa política e para se manter atualizado e ciente de qualquer eventual atualização.`}</p>
              </section>
              <section>
                <h4>{t`Prazo de Armazenamento`}</h4>
                <p>{t`Os dados pessoais dos USUÁRIOS serão mantidos pelo período que se mostrar necessário para cumprir suas respectivas finalidades e o término do tratamento deve ocorrer após constatação que a finalidade foi alcançada ou de que os dados não são mais necessários ou pertinentes ao alcance da finalidade específica e declarada.`}</p>
                <p>{t`A exclusão ou anonimização dos dados pessoais poderá ocorrer após término de contrato, ou, por solicitação do USUÁRIO, exceto os dados que receberem diferente tratamento por força de lei.`}</p>
                <p>{t`Atingida a finalidade e encerrado período de tratamento dos dados, estes só poderão ser conservados para além de referido prazo nas hipóteses previstas no art. 16 da Lei Geral de Proteção de Dados:`}</p>
                <p><b>{t`I -`}</b> {t`Cumprimento de obrigação legal ou regulatória pelo controlador;`}</p>
                <p><b>{t`II -`}</b> {t`Estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;`}</p>
                <p><b>{t`III -`}</b> {t`Transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos nesta Lei; ou`}</p>
                <p><b>{t`IV -`}</b> {t`Uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados.`}</p>
              </section>
              <section>
                <h4>{t`Foro`}</h4>
                <p>{t`Para dirimir quaisquer controvérsias oriundas do presente Instrumento, as partes elegem o Foro da Comarca de São Paulo, sendo aplicáveis as Leis da República Federativa do Brasil.`}</p>
              </section>
            </Modal.Body>
          </Modal>
        </div>
      </Div>
    </Fragment>
  );
}
