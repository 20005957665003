import { combineReducers } from "redux";

import viewMode from "./viewMode/reducer";
import inventario from "./inventario/reducer";
import profile from "./profile/profileReducer";
import cliente from "./cliente/clienteReducer";

export default combineReducers({
  viewMode,
  inventario,
  profile,
  cliente
});
