import axios from "axios";

const apicompose = axios.create({
  baseURL: `${process.env.REACT_APP_DNS_COMPOSE}/`, 
});

apicompose.interceptors.request.use(async (config) => {
  const TOKEN = `-lS5FPwVUX3i_ukLjAZDw2XgpI-CG4LOu3w6wAyhWuklAzFuqCKu5w==`;

  try {
    config.headers = {
     apikey: TOKEN 
    };
    return config;
  } catch (err) {
    console.log(err);
  }
});


export default apicompose;
