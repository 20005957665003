import React, { useState, useEffect } from "react";
import useSWR from "swr";
import api from "../services/api";

export function useRequesitionGet(url, options) {
  const { data, error } = useSWR(url, async (url) => {
    const response = await api.get(url);
    return response.data;
  }, options);
  return { data, error };
}

export const useRequesitionGetApi = (url) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const res = await api.get(url);
      setData(res.data);
    };
    getData();
  }, []);

  return [data];
};
