import { createGlobalStyle } from "styled-components";
import watermarkDev from "../assets/img/watermark-dev.png";
import watermarkUat from "../assets/img/watermark-uat.png";
import watermarkNd from "../assets/img/watermark-notdefined.png";

export default createGlobalStyle`
    *{
        padding: 0;
        margin: 0;
        outline: none;
    }

    table{
      white-space: nowrap;
    }

    .fade-enter{
        opacity: 0;
        transform: translateX(10%);
    }
    .fade-exit{
        opacity: 1;
        transform: translateX(0%);
    }
    .fade-enter-active{
        opacity: 1;
        transform: translateX(0%);
    }
    .fade-exit-active{
        opacity: 0;
    }
    .fade-enter-active,
    .fade-exit-active{
        transition: opacity 500ms, transform 500ms;
    }
    
    body > div > div#_hj_feedback_container div[data-emotion-score] span,
    body > div > div#_hj_feedback_container span[data-emotion-score] span {
       display: inline !important
    } 

    .react-form-builder-toolbar{
      padding-top: 15px;
    }

    .icon-sideNav,.sidenav-link{
      color: #9d9fa1; 
    } 

    .icon-sideNav:hover,.sidenav-link:hover{
      color: white;
    }

    .ui-bordered {
        border: 1px solid rgba(24,28,33,0.06);
    }
    
    .ql-container.ql-snow, .ql-toolbar.ql-snow, .css-yk16xz-control, .form-control{
      border: 1px solid rgba(24,28,33,0.1) !important;
      display: block;
      width: 100%;
      font-size: .894rem;
      font-weight: 400;
      line-height: 1.54;
      color: #4E5155;
      background-color: #fff;
      background-clip: padding-box;
      border-radius: .25rem;
      transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    }

    .react-form-builder .react-form-builder-preview{
        min-height: 0px !important;
    }

    .react-form-builder .react-form-builder-toolbar{
      margin-top: 0 !important;
      width: 28% !important;
    }

    .btnxs:nth-child(1) {
        border-top-left-radius: .25rem;
        border-bottom-left-radius:.25rem ;
    }
    
    .btnxs:nth-last-child(1) {
        border-top-right-radius: .25rem;
        border-bottom-right-radius:.25rem ;
    }
    
    .react-bs-table-sizePerPage-dropdown{
        margin-left: 1rem !important;
    }
    
    .react-bootstrap-table-page-btns-ul{
        float: right;
        margin-right: 1rem !important;
        box-shadow: none !important;
        padding: 0 .5rem !important;
        font-size: .75rem !important;
        line-height: 1.5 !important;
    }
    .border-none{
      border: none !important;
    }

    .quebraLinha {
      white-space: normal;
    }
    
    button:hover{
      cursor: pointer;
    }

    /**/

    .btn, span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .cursor-not-allowed:hover { 
    cursor: not-allowed;
    }

    .app-brand.demo {
        height: 66px;
    }
    
    .material-style .sidenav .app-brand.demo {
        height: 70px;
    }
    
    .app-brand-logo.demo {
        display: flex;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
    }
    
    .app-brand-logo.demo svg {
        width: 20px;
        height: 11px;
    }
    
    .app-brand-text.demo {
        font-size: 1.1rem;
    }

    .layout-sidenav {
        box-shadow: 1px 1px 0 0px rgba(24, 28, 33, 0.04) !important;
    }

    .app-brand.demo {
        height: 66px;
      }
      
      .material-style .sidenav .app-brand.demo {
        height: 70px;
      }
      
      .app-brand-logo.demo {
        display: flex;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
      }
      
      .app-brand-logo.demo svg {
        width: 20px;
        height: 11px;
      }
      
      .app-brand-text.demo {
        font-size: 1.1rem;
      }
      
      .layout-1 .layout-sidenav .app-brand.demo,
      .layout-1 .layout-sidenav .app-brand.demo + .sidenav-divider,
      .layout-reversed .layout-2 .layout-sidenav .app-brand.demo {
        display: none;
      }
      
      .layout-1 .layout-navbar .app-brand.demo,
      .layout-reversed .layout-2 .layout-navbar .app-brand.demo {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
      }
      
      .layout-1 .layout-navbar .layout-sidenav-toggle,
      .layout-reversed .layout-2 .layout-navbar .layout-sidenav-toggle,
      .layout-offcanvas .layout-navbar .layout-sidenav-toggle,
      .layout-fixed-offcanvas .layout-navbar .layout-sidenav-toggle {
        display: block !important;
      }
      
      @media (min-width: 992px) {
        .layout-offcanvas .layout-sidenav .layout-sidenav-toggle,
        .layout-fixed-offcanvas .layout-sidenav .layout-sidenav-toggle {
          display: none;
        }
      }
      
      hr[class*="bg-secondary"]{
        background-color: #eee !important;
        border: 0 none !important;
        color: #e6e6e6 !important;
        height: 2px !important;        
      }
      
      /* *****************************************************************************
       * Navbar
       */
      
      .demo-navbar-messages .dropdown-toggle,
      .demo-navbar-notifications .dropdown-toggle,
      .demo-navbar-user .dropdown-toggle,
      .demo-navbar-messages.b-nav-dropdown .nav-link,
      .demo-navbar-notifications.b-nav-dropdown .nav-link,
      .demo-navbar-user.b-nav-dropdown .nav-link {
        white-space: nowrap;
      }
      
      .demo-navbar-messages .dropdown-menu,
      .demo-navbar-notifications .dropdown-menu {
        overflow: hidden;
        padding: 0;
      }
      
      @media (min-width: 992px) {
        .demo-navbar-messages .dropdown-menu,
        .demo-navbar-notifications .dropdown-menu {
          margin-top: .5rem;
          width: 22rem;
        }
      
        .demo-navbar-user .dropdown-menu {
          margin-top: .25rem;
        }
      }
      
      
      /* *****************************************************************************
       * Content
       */
      
      .demo-vertical-spacing > * + * {
        margin-top: 1.875rem !important;
        margin-bottom: 0 !important;
      }
      
      .demo-vertical-spacing-sm > * + * {
        margin-top: .9375rem !important;
        margin-bottom: 0 !important;
      }
      
      .demo-vertical-spacing-lg > * + * {
        margin-top: 5rem !important;
        margin-bottom: 0 !important;
      }
      
      .demo-inline-spacing > *,
      .demo-paragraph-spacing > p > * {
        margin: 0 .375rem .9375rem 0 !important;
      }
      
      .demo-paragraph-spacing > p {
        margin: 0;
      }
      
      .demo-blocks > * {
        display: block !important;
      }
      
      html:not(.material-style) .material-style-only {
        display: none !important;
      }
      
      .rtl-only {
        display: none !important;
        text-align: left !important;
        direction: ltr !important;
      }
      
      [dir=rtl] .rtl-only {
        display: block !important;
      }
      
      /* *****************************************************************************
       * Layouts
       */
      
      .layout-example-block {
        margin-top: 10px;
        padding: 10px;
        border: 1px solid rgba(0,0,0,.04);
        border-radius: 2px;
        background: rgba(0,0,0,.04);
        color: #c7254e;
      }
      .layout-example-block code {
        background: none;
        color: #666;
      }
      
      .layout-example-block-inner {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        height: 500px;
      }
      
      .layout-example-block-sidenav {
        width: 24%;
        background: #607d8b;
      }
      .layout-example-block-sidenav code {
        color: #fff;
      }
      
      .layout-example-block-navbar {
        background: #3ca99e;
      }
      .layout-example-block-navbar code {
        color: #fff;
      }
      
      .layout-example-block-content {
        background: #9e9e9e;
      }
      .layout-example-block-content code {
        color: #fff;
      }
      
      .layout-example-block-1 .layout-example-block-content {
        width: 74%;
      }
      
      .layout-example-block-2 .layout-example-block-container {
        display: flex;
        flex-direction: column;
        width: 74%;
      }
      .layout-example-block-2 > .layout-example-block {
        flex: 0 0 auto;
      }
      .layout-example-block-2 .layout-example-block-content {
        flex-grow: 1;
      }
      
      .layout-example-block-1-no-sidenav .layout-example-block-content {
        width: 100%;
      }
      
      .layout-example-code {
        display: block;
        overflow: auto;
        margin: 0;
        padding: 30px;
        min-width: 0;
        width: 100%;
        background: #fff;
      }
      
      .layout-example-run {
        display: inline-block;
        margin: 3px;
        padding: 4px 6px;
        border: 1px solid #eee;
        background: transparent;
        white-space: nowrap;
        line-height: 1;
      }
      
      .layout-example-block-3 .layout-example-block-inner {
        display: flex;
        flex-direction: column;
      }
      .layout-example-block-3 .layout-example-block-inner > .layout-example-block {
        flex: 0 0 auto;
      }
      .layout-example-block-3 .layout-example-block-inner .layout-example-block-container-fluid {
        flex-grow: 1;
      }
      .layout-example-block-3 .layout-example-block-sidenav-horizontal {
        background: #607d8b;
      }
      .layout-example-block-3 .layout-example-block-container-fluid {
        background: #fff;
      }
      .layout-example-block-3 .layout-example-block-container-fluid code {
        color: #666;
      }
      .layout-example-block-3 .layout-example-block-footer {
        background: #e6514a;
      }
      
    @media only screen and (max-width: 670px) {
        .text-tiny .font-weight-normal {
        font-size: 11px;
        }
    }

    /* style para componente de legenda e gráfico de pizza */
    .pie-graph-legend{
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
    }
    
    /* style para legenda do componente gráfico de pizza */
    .pie-legend {
      display: flex;
      flex-direction: column;
    }

    /* style para gráfico de pizza */
    .pie-graph {
      height: 100%;
    }

    @media only screen and (max-width: 768px) {
        #pageDropDown , .react-bootstrap-table-pagination-total {
        display: none;
        }
        .pie-graph-legend{
          flex-direction: column;
          height: 300px;
        }
        .pie-graph{
          height: 75%;
        }
        .pie-legend{
          flex-direction: row;
          align-items: center;
          height: 25%;
          gap: 15px;
        }
    }

    /* style for the stick, fixed column*/
    .react-bootstrap-table > table > thead > tr:nth-child(1) > th:nth-child(1) {
      background-color: #f2f2f2;
      border-left:  #666;
      border-bottom: #666;
      position: sticky;
      position: -webkit-sticky;
      left: 0;  
      z-index: 1;
    }

    .react-bootstrap-table > table > tbody > tr > td:nth-child(1){
      position: sticky;
      position: -webkit-sticky;
      left: 0;
      background-color: #fff;
      border-left:  #666;
      border-bottom: #666;
      z-index: 1;  
    }

    /* style for the stick, fixed column*/
    .react-bootstrap-table > table[class*="tabela-prefatura"] > thead > tr:nth-child(1) > th:nth-child(2) {
      background-color: #fff;
      border-left:  #666;
      border-bottom: #666;
      position: sticky;
      position: -webkit-sticky;      
      left: 76.1px;
      box-shadow:inset 0px 0px 0.1px 0px #666;
      z-index: 1;
    }

    .react-bootstrap-table > table[class*="tabela-prefatura"] > tbody > tr > td:nth-child(2) {
      position: sticky;
      position: -webkit-sticky;
      background-color: #fff;
      border-left:  #666;
      border-bottom: #666;      
      left: 76.1px;
      box-shadow:inset 0px 0px 0.7px 0px #666;
      z-index: 1;  
    }

    /* style for the stick, fixed column*/
    .react-bootstrap-table > table[class*="tabela-ativo"] > thead > tr:nth-child(1) > th:nth-child(2) {
      background-color: #fff;
      border-left:  #666;
      border-bottom: #666;
      position: sticky;
      position: -webkit-sticky;      
      left: 93px;
      box-shadow:inset 0px 0px 0.1px 0px #666;
      z-index: 1;
    }

    .react-bootstrap-table > table[class*="tabela-ativo"] > tbody > tr > td:nth-child(2) {
      position: sticky;
      position: -webkit-sticky;
      background-color: #fff;
      border-left:  #666;
      border-bottom: #666;      
      left: 93px;
      box-shadow:inset 0px 0px 0.7px 0px #666;
      z-index: 1;  
    }

    .react-bootstrap-table > table[class*="tabela-ativo-ro"] > thead > tr:nth-child(1) > th:nth-child(2) {
      background-color: #fff;
      border-left:  #666;
      border-bottom: #666;
      position: sticky;
      position: -webkit-sticky;      
      left: 75px;
      box-shadow:inset 0px 0px 0.1px 0px #666;
      z-index: 1;      
    }    
    
    .react-bootstrap-table > table[class*="tabela-ativo-ro"] > tbody > tr > td:nth-child(2) {
      position: sticky;
      position: -webkit-sticky;
      background-color: #fff;
      border-left:  #666;
      border-bottom: #666;      
      left: 75px;
      box-shadow:inset 0px 0px 0.7px 0px #666;
      z-index: 1;        
    }

    .react-bootstrap-table {
      min-height: 320px;
      max-height:calc(100vh - 160px);
      z-index: 0;
    }

    .react-bootstrap-table > table > thead {
      position: sticky;
      position: -webkit-sticky;
      top:0;
      background-color: #f2f2f2;
      border: #666;
      z-index: 2;
    }

    /*keeping columns aligned during scrolling*/
    .react-bootstrap-table {
      max-width: 100%;
      overflow: overlay !important;
    }

    .dropdown-menu.show {
      z-index: 3 !important;
    }
    
    .backoffice-steps {
      background-color: #fff;
      border-radius: 5px;
      border: solid 1px #eeeeee;

      .multi-step {
        .progtrckr {
          border-bottom: solid 1px #eeeeee;
          padding: 25px 25px 20px;
          
          .progtrckr-todo, .progtrckr-done {
            .step-title {
              .stepzilla-number {
                border-color: rgba(24, 28, 33, .06);
                color: #4E5155 !important;
              }
            }
          }

          li {
            margin-bottom: 0;
          }
        }

        .footer-buttons {
          padding: 15px 25px;

          .btn-primary {
            background: #26B4FF !important;
          }
        }
      }
    }

    /* tratamento específico p/ "visualizar" o h5 como label, contexto [tipo ativo]*/
    div.row > div.col-12 > div.form-group > div > div.p-0.m-0.row > div.p-0.m-0.col > div.my-1 > h5 {
      margin-bottom: calc(0.438rem - 2px);
      font-weight: 500;
      font-size: .83125rem;
      padding-bottom: 0;      
    }

    .verticalThead {
      background-color: #ea5036;
      color: #ffffff;
    }

    /* page links componente de paginação */
    .disabled-page-link,
    .disabled-page-item{
      display: none;
    }
    /* page links componente de paginação */
    .page-item,
    .page-link{
      cursor: pointer;
    }

    /* 4 child do componente de paginação em negrito */
    .table-card-pagination:nth-child(4) > a{
      font-wigth: bold;
    }
    
    /* tamanho componente TableCard */
    .table-card{
      min-height: 310px;
      max-height: calc(100vh - 190px);
      z-index: 0;
      overflow-x: hidden !important;
    }

    /* parte de dentro dos cards da TableCard */
    .table-card-text {
      display: block;
      min-height: 25px;
      max-height: 100px;
      text-overflow: ellipsis;
      white-space: normal;
      overflow: hidden;
      word-break: break-word;
    }

    /* desabilitando 6 child do componente de paginação para ficar igual ao do bootstrap */
    .table-card-page-item:nth-child(6){
      display: none;
    }

    /* TUI image editor buttons */
    .tui-image-editor-header-logo,
    .tie-btn-reset.tui-image-editor-item.help.enabled{
      display: none;
    }

    *[class*="environment-watermark-"] {
      background-image: url(${watermarkNd});
      background-size: 85%;
      background-position: right;
      background-repeat: no-repeat;
      background-clip: padding-box;
      opacity: 0.7;
    }

    .environment-watermark-dev {
      background-image: url(${watermarkDev});
      background-size: 85%;
      background-position: right;
      background-repeat: no-repeat;
      background-clip: padding-box;
      opacity: 0.7;
    }

    .environment-watermark-uat {
      background-image: url(${watermarkUat});
      background-size: 85%;
      background-position: right;
      background-repeat: no-repeat;
      background-clip: padding-box;
      opacity: 0.7;
    }

    .environment-watermark-prod {
      background-image: none;
    }

    .homepage {
      border: 0;
      background-color: transparent;
    }

    .homepage:focus {
      outline: 0 !important;
    }

    .table-card-permissionamento{
      transform: scale(1);
      transition: transform 200ms ease-in;
    }
    .table-card-permissionamento:hover{
      transform: scale(1.02);
    }

    .sidenav.bg-dark {
      background-color: #414449 !important;
    }

    .sidenav.bg-dark .sidenav-link,
    .sidenav.bg-dark .sidenav-horizontal-prev,
    .sidenav.bg-dark .sidenav-horizontal-next {
      color:#bbbaba;
    }

    @supports ((position: -webkit-sticky) or (position: sticky)){
      /* .sticky-top{position:-webkit-sticky;position:sticky;top:0;z-index:1020} já definido no bootstrap */
      .sticky-bottom{position:-webkit-sticky;position:sticky;bottom:0;z-index:1020}
      .row.react-bootstrap-table-pagination{margin-left:revert;margin-right:revert}
      .react-bootstrap-table-pagination{position:-webkit-sticky;position:sticky;bottom:0;z-index:1020;backgroundColor:#fff}
      .react-bootstrap-table-pagination > div {background-color:#fff}
      .react-bootstrap-table-pagination > div:first-child {padding-left:0}
      .react-bootstrap-table-pagination > div:last-child {padding-right:0}
    }

    /* *****************************************************************************
    * Toolbox criação tipo de ativo
    */
    @media (min-width: 768px){
      div.react-form-builder > div{
        position: relative;
        overflow: auto;
        max-height: calc(100vh - 180px);;
      }

      div.react-form-builder > div > div.react-form-builder-toolbar {
        position: sticky;
        right: 0;
        top: 0;
        padding: 10px;
        border: 1px solid rgba(0,0,0,0.1);
      } 
    }

    .text-hidden-off-hover > span.text-hidden-off-hover {
      display: inline-flex;
      transition: 2s;
      max-width: 0px;
      max-height: 0px;
      overflow: hidden;
    }

    .text-hidden-off-hover:hover > span.text-hidden-off-hover {
      display: inline-flex;
      transition: 2s;
      max-width: 9999px;
      max-height: 9999px;
      overflow: visible;
    }
`;
