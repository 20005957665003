import React, { useState, Fragment, useEffect } from "react";
import { Navbar, Nav, Dropdown, ListGroup, Media, Button, ButtonGroup } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import socketIOClient from "socket.io-client";
import layoutHelpers from "../../shared/layouts/helpers";
import "react-datepicker/dist/react-datepicker.css";
import { useNotificationCenter } from "react-toastify/addons/use-notification-center";
import { useDispatch, useSelector } from "react-redux";
import useRefreshToken from "../../hooks/useRefreshToken";

//components
import ComboCliente from "../ComboCliente";
import Select from "react-select";

//styles
import { BadgeIcon, BadgeSpan } from "./style";

//utils
import { formatData, formatDataReduced } from "../../utils/dataFormat";

//services
import apicompose from "../../services/api-compose";

export default function SideBar(props) {
  const dispatch = useDispatch();
  const viewMode = useSelector((state) => state.viewMode.position);
  useRefreshToken();

  const listaOptionsLanguage = [
    { label: "Português (brasil)", value: "PT-BR" },
    { label: "English", value: "EN" },
    { label: "Español", value: "ES" }
  ];

  const isRTL = document.documentElement.getAttribute("dir") === "rtl";
  const history = useHistory();
  //state
  const [collapsed, setCollapsed] = useState(false);
  const [notification, setNotification] = useState(JSON.parse(sessionStorage.getItem('toast-notifications') || '[]'));
  const [quantityShowNotifications, setQuantityShowNotifications] = useState(3);
  const [visibleNotification, setVisibleNotification] = useState(false);
  const [startDate, setDates] = useState(new Date());
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [jiraNotification, setJiraNotification] = useState(JSON.parse(sessionStorage.getItem('jira-notification') || '[]'));

  const [showLanguagePicker, setShowLanguagePicker] = useState(false);
  const [objLanguage, setObjLanguage] = useState(listaOptionsLanguage.find(e => e.value.toLocaleLowerCase() === localStorage.getItem("Locale")?.toLocaleLowerCase()));

  const { notifications: newNotification } = useNotificationCenter();

  useEffect(() => {
    let isMounted = true;
    const getData = async () => {
      try {
        let { data: response } = await apicompose.get(`notification/v1?email=${localStorage.getItem('User')}`)

        if(isMounted && response.length > 0)
          setJiraNotification(response);

        if (isMounted && !response.length === 0)
          setJiraNotification([false]);

        sessionStorage.setItem('jira-notification', JSON.stringify(response && response.length > 0 ? response : [false]))
      }
      catch (err) {
        console.log(err)
      }
    }

    if (jiraNotification.length === 0)
      getData();

    return (() => {
      isMounted = false;
    })
  }, [jiraNotification])

  useEffect(() => {
    let validNewJiraNotification= [];
    jiraNotification.forEach((jiraItem) => {
      if (!!(jiraItem?.id && !notification?.find((item) => item?.id === jiraItem?.id))){
        validNewJiraNotification.push(jiraItem)
      };
    })
    if(validNewJiraNotification.length > 0 || !notification.includes(newNotification)){
        let allMergedNotifications = [];
        if(validNewJiraNotification.length > 0){
          allMergedNotifications = [...notification, ...validNewJiraNotification, ...newNotification];
        }
        else{
          allMergedNotifications = [...notification, ...newNotification];
        }
        
        const renewedNotifications = [...new Set(allMergedNotifications)];
        setNotification(renewedNotifications);
        
        const setLocalStorage = [...new Set([...notification, ...newNotification])]
        sessionStorage.setItem('toast-notifications', JSON.stringify(setLocalStorage && setLocalStorage.length > 0 ? setLocalStorage : []));
    }
  }, [newNotification, jiraNotification]);

  const bgTypeClass = {
    error: {
      class: "ion ion-md-alert",
      color: "rgba(255, 0, 0)"
    },
    success: {
      class: "ion ion-md-checkmark-circle",
      color: "rgba(13, 255, 0)"
    },
    info: {
      class: "ion ion-md-information-circle",
      color: "rgba(3, 91, 255)"
    },
    warning: {
      class: "ion ion-md-warning",
      color: "rgba(223, 242, 7)"
    }
  }

  const LogOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    history.push('/');
    history.go(0);
  };

  //filtrar por data
  const handleChange = (date) => {
    setDates(date);
    localStorage.setItem("Data", date);
    setShowDatePicker(false);
    window.location.reload();
  };

  //button menu
  const toggleSidenav = (e) => {
    e.preventDefault();
    layoutHelpers.toggleCollapsed();

    setCollapsed(layoutHelpers.isCollapsed());
  };

  const handleChangeQuantidade = (e) => {
    if(notification.length < 3 || notification.length <= quantityShowNotifications) 
      return;
    setQuantityShowNotifications(quantityShowNotifications + 3);
  }

  const handleVisibleNotification = () => {
    setVisibleNotification(!visibleNotification);
  }

  const markAsRead = (id) => {
    let oldNotificationList = notification.filter((e) => e.id !== id)
    let item = notification.filter(e => e.id === id)
    item.map((i) => i.isRead = !i.isRead)
    const newReadNotificationList = [...oldNotificationList, ...item];

    setNotification(newReadNotificationList);
    sessionStorage.setItem('toast-notifications', JSON.stringify(newReadNotificationList));
  }

  const handleLanguageChange = (data) => {
    setObjLanguage(data);
    localStorage.setItem("Locale", data?.value);

    setShowLanguagePicker(false);
    history.go(0);
  }

  const homeOnClick = async () => {
    history.push(JSON.parse(localStorage.getItem("Homepath")) ?? "/dashboard");
  }

  return (
    <div>
      <Navbar
        expand="lg"
        className="layout-navbar align-items-lg-center container-p-x"
      >
        <div className="mr-4" style={{ width: "160px" }}>
          <button className="homepage">
            <img
              className={`w-100 environment-watermark-${process.env.REACT_APP_ENVIRONMENT}`}
              src={require("../../assets/img/btb-logo.png")}
              alt="BTB Soluções"
              onClick={ homeOnClick }
            />
          </button>
        </div>
        <div>
          <ButtonGroup>
          {viewMode === "vertical" &&
            <Button
              variant="default icon-btn md-btn-flat d-none d-sm-block"
              onClick={toggleSidenav}
            >
              <i className={collapsed ? "ion ion-md-menu" : "ion ion-md-more"}></i>
            </Button>
            }
            {viewMode !== "vertical" &&
            <Button
              variant="default icon-btn md-btn-flat d-none d-sm-block"
              onClick={() =>
                dispatch({ type: "SET_VIEW_MODE", payload: "vertical" })
              }
              active={viewMode === "vertical"}
            >
              <i className="ion ion-md-menu"></i>
            </Button>
            }

            {viewMode !== "horizontal" &&
            <Button
              variant="default icon-btn md-btn-flat d-none d-sm-block"
              onClick={() =>
                dispatch({ type: "SET_VIEW_MODE", payload: "horizontal" })
              }
              active={viewMode === "horizontal"}
            >
              <i className="ion ion-md-apps"></i>
            </Button>
            }
          </ButtonGroup>
        </div>
        <Navbar.Toggle />

        <Navbar.Collapse>
          {/* Divider */}
          <hr className="d-lg-none w-100 my-2" />

          <Nav className="align-items-lg-center ml-auto">
            <Dropdown
              as={Nav.Item}
              className="demo-navbar-notifications mr-lg-2"
              alignRight={!isRTL}
            >
              <Dropdown.Toggle as={Nav.Link} className="hide-arrow">
                <BadgeIcon
                  onClick={handleVisibleNotification}
                  visible={visibleNotification}
                  msg={notification.filter((e) => !e.isRead).length || 0}
                  className="ion ion-md-notifications-outline navbar-icon align-middle"
                ></BadgeIcon>
                {!!notification.filter((e) => !e.isRead).length && (
                  <BadgeSpan msg={notification.filter((e) => !e.isRead).length || 0}></BadgeSpan>
                )}
                <span className="d-lg-none align-middle">
                  &nbsp; Notifications
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <div className="bg-primary text-center text-white font-weight-bold p-3">
                  {notification.filter((e) => !e.isRead).length} New Messages
                </div>
                <ListGroup variant="flush" style={{ maxHeight: 450, overflowY: quantityShowNotifications > 3 ? 'scroll' : 'none' }}>
                  {notification.length > 0 &&
                    notification?.sort(function (a, b) {
                      return new Date(b.createdAt) - new Date(a.createdAt);
                    }).slice(0, quantityShowNotifications).map((item) => (
                      <ListGroup.Item
                        as={Media}
                        action
                        className={`d-flex align-items-center border border-light ${item.isRead ? "bg-light bg-gradient" : ""}`}
                        onClick={() => markAsRead(item.id)}
                        key={item.id}
                      >
                        <Media.Body className="line-height-condenced ml-3">
                          <div className={"text-body"}>
                            <Fragment>
                              <div className="mt-2 mb-2">
                                <span className="font-weight-bold quebraLinha">
                                  {item.username || "Toast"}
                                </span>
                              </div>
                              <div className="mt-2 mb-2">
                                <span className="quebraLinha">
                                  {item.content}
                                </span>
                              </div>
                              <div className="small mt-1 d-flex justify-content-between">
                                {formatData(item.createdAt)}
                                <div>
                                  <i
                                    className={`${bgTypeClass[item.type]?.class}`}
                                    style={{ color: bgTypeClass[item.type]?.color, fontSize: 18 }}
                                  ></i>
                                </div>
                              </div>
                            </Fragment>
                          </div>
                        </Media.Body>
                      </ListGroup.Item>
                    ))}
                </ListGroup>

                {/* <Link
                  to="/logs"
                  className="d-block text-center text-light small p-2 my-1"
                >
                  Show all messages
                </Link> */}
                <span
                  role={"button"}
                  className="text-light d-block text-center text-light small p-2 my-1 cursor-pointer"
                  onClick={handleChangeQuantidade}
                >
                  Show more messages
                </span>
              </Dropdown.Menu>
            </Dropdown>

            {/* Divider */}
            <div className="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-1 ml-1">
              |
            </div>

            <ComboCliente />

            {/* Divider */}
            <div className="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-1 ml-2">
              |
            </div>

            <i
              className="d-block ion ion-md-calendar navbar-icon align-middle btn btn-sm icon-btn btn-outline-secondary borderless"
              onClick={() => setShowDatePicker(!showDatePicker)}
            ></i>
            {showDatePicker ? (
              <DatePicker
                className="form-control ml-1 "
                selected={startDate}
                onChange={handleChange}
                onBlur={() => setShowDatePicker(false)}
                dateFormat="MM/yyyy"
                showMonthYearPicker
              />
            ) : (
              <span
                className="text-black-sidebar ml-1 cursor-pointer"
                onClick={() => setShowDatePicker(!showDatePicker)}
              >
                {formatDataReduced(
                  localStorage.getItem("Data")
                    ? localStorage.getItem("Data")
                    : startDate
                )}
              </span>
            )}

            {/* Divider */}
            <div
              className="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 
            mr-2 ml-2"
            >
              |
            </div>

            <i className="d-block ion ion-ios-globe navbar-icon align-middle btn btn-sm icon-btn btn-outline-secondary borderless"
              onClick={() => setShowLanguagePicker(!showLanguagePicker)}
            ></i>

            {showLanguagePicker ? (
              <div style={{ "width": "200px" }}>
                <Select
                  className={`custom-select p-0 border-1 ml-1 ${(!showLanguagePicker) ? 'is-invalid' : ''}`}
                  classNamePrefix="select"
                  options={listaOptionsLanguage}
                  onChange={handleLanguageChange}
                  value={objLanguage}
                />
              </div>
            ) : (
              <span
                className="text-black-sidebar ml-1 cursor-pointer"
                onClick={() => setShowLanguagePicker(true)}
              >
                {objLanguage?.label}
              </span>
            )}

            {/* Divider */}
            <div
              className="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 
            mr-2 ml-2"
            >
              |
            </div>

            <span className="ml-2 ml-lg-0 text-black-sidebar">
              Bem Vindo, {localStorage.getItem("User")}
            </span>

            <Dropdown
              as={Nav.Item}
              className="demo-navbar-user"
              alignRight={!isRTL}
            >
              <Dropdown.Toggle as={Nav.Link}>
                <span className="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                  <span className="app-brand-logo demo bg-primary">
                    <strong className="text-white">
                      {localStorage
                        .getItem("User")
                        ?.substr(0, 2)
                        .toLocaleUpperCase()}
                    </strong>
                  </span>
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => LogOut()}>
                  <i className="ion ion-ios-log-out text-danger"></i> &nbsp; Log
                  Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}
