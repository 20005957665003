const INITIAL_STATE = {
  qtdPerfis: 0,
  qtdProjetos: 0,
  qtdLinhas: 0,
  qtdPContas: 0,
  qtdAtivos: 0,
};

const profile = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_PROFILE":
      const newState = { ...state };

      newState.qtdPerfis = action.payload.qtdPerfis;
      newState.qtdProjetos = action.payload.qtdProjetos;
      newState.qtdLinhas = action.payload.qtdLinhas;
      newState.qtdPContas = action.payload.qtdPContas;
      newState.qtdAtivos = action.payload.qtdAtivos;

      return newState;

    default:
      return state;
  }
};

export default profile;
